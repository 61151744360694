import React, { useState, useEffect, useRef } from 'react';
import { Button, Avatar, Panel as RsuitePanel, Dropdown, Input } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import styled from 'styled-components';
import axios from 'axios';
import { getApiUrl } from '../../config';
import MultiStepForm from './MultiStepForm';
import { useAnalytics } from './AnalyticsContext';
import BottomSheet from './BottomSheet';
import ChatSend from './ChatSend';
import { signInAnonymously, onAuthStateChanged } from 'firebase/auth';
import { ref, onChildAdded, onValue, get } from 'firebase/database'; // Update existing import to include 'get'import { signInAnonymously, onAuthStateChanged } from 'firebase/auth';
import { db, auth } from './fbclient';

const BusinessHoursContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.95);
  border-bottom: 1px solid #eee;
  z-index: 10;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
`;

const LeaveMessageForm = styled.div`
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  padding-bottom: 40px; // Add extra padding at the bottom
  position: relative;
  max-height: calc(100% - ${props => props.buttonheight || 0}px);
  -webkit-overflow-scrolling: touch;

  /* Create a padding container for the content */
  &::after {
    content: '';
    display: block;
    height: 20px; // Additional space after the last message
  }

  /* Firefox scrollbar */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.0) transparent;

  margin-bottom: ${props => props.buttonheight}px;
  transition: margin-bottom 0.3s ease;
`;

const Panel = styled(RsuitePanel)`
  height: 100%;
  height: 100%; // Take up the full parent height
  overflow: hidden; // Prevent content overflow
  position: relative; // Ensure it positions relative to the parent
`;


const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => props.backgroundColor ? props.backgroundColor : '#042b60'};
  padding: 14px;
  z-index: 10;
  overflow-y: auto;
  transform: translateY(${props => props.isExiting ? '100%' : '0'});
  transition: all 0.3s ease-out;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-top: 20px; 
  max-height: 400px;
  border-top : 1px solid #d7d7d94d;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
    font-size: 14px;
  overflow: hidden;
  text-align:start;
  padding-bottom: ${props => props.hasButtons ? '0' : '20px'}; // Add padding when no buttons
`;

const AnimatedButton = styled.button`

  padding: 12px 14px;
  background-color: ${props => props.themeColor || '#007bff'};
  border: none;
  border-radius: 32px;
  color: white;
  cursor: pointer;
  text-align: center;
  display: block;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  min-width: 120px;
  max-width: 300px;
`;

const ChatMessage = styled.div`
  @keyframes appearMessage {
    0% {
      opacity: 0;
      transform: scale(0.96) ${props => props.type === 'user' ? 'translateX(8px)' : 'translateX(-8px)'};
    }
    100% {
      opacity: 1;
      transform: scale(1) translateX(0);
    }
  }

  margin-bottom: ${props => props.type === 'user' ? '10px' : '5px'};
  margin-top: ${props => props.type === 'user' ? '10px' : '5px'};
  display: flex;
  flex-direction: ${props => props.type === 'user' ? 'row-reverse' : 'row'};
  align-items: flex-start;
  gap: 10px;
  position: relative;
  opacity: 0;
  animation: appearMessage 0.2s cubic-bezier(0.33, 1, 0.68, 1) forwards;
  animation-delay: ${props => props.delay};
`;


const extractBaseURL = (url) => {
    try {
        // Find the position of first '/' after the protocol
        const protocolEnd = url.indexOf('//');
        if (protocolEnd !== -1) {
            const pathStart = url.indexOf('/', protocolEnd + 2);
            if (pathStart !== -1) {
                return url.substring(0, pathStart + 1);
            }
        }
        // If no path is found, return the original URL with trailing slash
        return url.endsWith('/') ? url : url + '/';
    } catch (error) {
        return '';
    }
};

const isColorDark = (color) => {
    // Handle null/undefined color
    if (!color) return false;

    // Convert hex to RGB
    let r, g, b;
    if (color.startsWith('#')) {
        const hex = color.replace('#', '');
        r = parseInt(hex.substr(0, 2), 16);
        g = parseInt(hex.substr(2, 2), 16);
        b = parseInt(hex.substr(4, 2), 16);
    } else if (color.startsWith('rgb')) {
        // Handle rgb/rgba format
        const values = color.match(/\d+/g);
        r = parseInt(values[0]);
        g = parseInt(values[1]);
        b = parseInt(values[2]);
    } else {
        return false;
    }

    // Calculate relative luminance using the formula
    // Darker colors have lower luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance < 0.5;
}

const TypingIndicator = () => (
    <div style={{
        display: 'flex',
        gap: '5px',
        padding: '14px',
        background: 'white',
        borderRadius: '10px',
        width: 'fit-content',
    }}>
        <div style={{
            width: '8px',
            height: '8px',
            backgroundColor: '#2196F3',
            borderRadius: '50%',
            animation: 'bounce 1s infinite'
        }} />
        <div style={{
            width: '8px',
            height: '8px',
            backgroundColor: '#2196F3',
            borderRadius: '50%',
            animation: 'bounce 1s infinite',
            animationDelay: '0.2s'
        }} />
        <div style={{
            width: '8px',
            height: '8px',
            backgroundColor: '#2196F3',
            borderRadius: '50%',
            animation: 'bounce 1s infinite',
            animationDelay: '0.4s'
        }} />
        <style>{`
      @keyframes bounce {
        0%, 100% { transform: translateY(0); }
        50% { transform: translateY(-5px); }
      }
    `}</style>
    </div>
);

export const ChatAvatar = ({ logoUrl, defaultText = 'B', isVisible = true, hideAvatar }) => {
    const [imageError, setImageError] = useState(false);
    const showDefaultText = !logoUrl || imageError;

    return (
        <div style={{
            width: hideAvatar ? '2px' : 'auto', // Adjust width based on your Avatar size
            display: 'flex',
            alignItems: 'center'
        }}>
            {!hideAvatar && (
                <Avatar
                    circle
                    size="sm"
                    src={showDefaultText ? null : logoUrl}
                    alt="Chat Avatar"
                    style={{
                        backgroundColor: defaultText === 'B' ? 'transparent' : 'lightgray',
                        padding: '2px',
                        border: '1px solid #bfbfbf',
                        color: "#fff",
                        visibility: isVisible ? 'visible' : 'hidden',
                    }}
                    onError={() => setImageError(true)}
                >
                    {showDefaultText && defaultText}
                </Avatar>
            )}
        </div>
    );
};


const ChatSimple = ({ openingMessage, widgetData, chatKey, innerWindowOpen }) => {
    const [messages, setMessages] = useState([]);
    const [currentOptions, setCurrentOptions] = useState(null);
    const [allFormFields, setAllFormFields] = useState(null);
    const [currentStepId, setCurrentStepId] = useState(openingMessage?.topicId ?? "start");
    const [chatFlow, setChatFlow] = useState({});
    const messagesEndRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const timeoutIds = useRef([]);
    const [isExitingButtons, setIsExitingButtons] = useState(false);
    const buttonContainerRef = useRef(null);
    const [buttonHeight, setButtonHeight] = useState(0);
    const analytics = useAnalytics();
    const [currentForm, setCurrentForm] = useState(null);
    const [showSheet, setShowSheet] = useState(false);
    const [liveChatOpen, setLiveChatOpen] = useState(false);
    const [aiChatOpen, setAiChatOpen] = useState(false);
    const [currentAction, setCurrentAction] = useState({});
    // We'll track the session ID in state or a ref for convenience
    // We set it once in some effect or initialization

    // Add these after other state variables, before useEffects
    const [businessHoursConfig, setBusinessHoursConfig] = useState(null);
    const [isWithinHours, setIsWithinHours] = useState(true);
    const [leaveMessageText, setLeaveMessageText] = useState('');
    const [leaveMessageName, setLeaveMessageName] = useState('');
    const [leaveMessageEmail, setLeaveMessageEmail] = useState('');
    const [leaveMessageSubmitted, setLeaveMessageSubmitted] = useState(false);

    const sessionIdRef = useRef(null);

    // Check if current time is within business hours
    const isWithinBusinessHours = (config) => {
        // If business hours are not enabled, consider always within hours
        if (!config?.enabled) {
            return true;
        }

        const now = new Date();
        const currentHour = now.getHours();
        const currentDay = now.getDay(); // 0 = Sunday, 1 = Monday, ...

        // Convert day of week number to string key
        const dayMap = {
            0: 'sunday',
            1: 'monday',
            2: 'tuesday',
            3: 'wednesday',
            4: 'thursday',
            5: 'friday',
            6: 'saturday'
        };

        const dayKey = dayMap[currentDay];
        const dayConfig = config.hours?.[dayKey];

        // If day is not enabled or not configured, consider outside of hours
        if (!dayConfig || !dayConfig.enabled) {
            return false;
        }

        // Check if current hour is within the configured range
        return currentHour >= dayConfig.start && currentHour < dayConfig.end;
    };

    // Get the next business hours start time
    const getNextBusinessHours = (config) => {
        if (!config?.enabled) {
            return null; // Always open
        }

        const now = new Date();
        const currentDay = now.getDay(); // 0 = Sunday, 1 = Monday, ...
        const currentHour = now.getHours();

        // Check all days, starting from current day
        for (let i = 0; i < 7; i++) {
            const checkDay = (currentDay + i) % 7;
            const dayMap = {
                0: 'sunday',
                1: 'monday',
                2: 'tuesday',
                3: 'wednesday',
                4: 'thursday',
                5: 'friday',
                6: 'saturday'
            };

            const dayKey = dayMap[checkDay];
            const dayConfig = config.hours?.[dayKey];

            // Skip days that are not enabled
            if (!dayConfig || !dayConfig.enabled) {
                continue;
            }

            // If checking current day and we're already past the start time
            if (i === 0 && currentHour >= dayConfig.start) {
                // If we're still within hours, return null (we're open now)
                if (currentHour < dayConfig.end) {
                    return null;
                }
                // Otherwise, continue to next day
                continue;
            }

            // Found the next business day
            const nextDate = new Date(now);
            nextDate.setDate(now.getDate() + i);
            nextDate.setHours(dayConfig.start, 0, 0, 0);
            return nextDate;
        }

        // If we get here, no business days were found
        return null;
    };

    const formatNextBusinessHours = (nextBusinessHours) => {
        if (!nextBusinessHours) {
            return '';
        }
    
        const now = new Date();
        const dayNames = [
            'sunnuntaina', 'maanantaina', 'tiistaina', 'keskiviikkona',
            'torstaina', 'perjantaina', 'lauantaina'
        ];
        const dayName = dayNames[nextBusinessHours.getDay()];
    
        // Format time: klo 9.00
        const hours = nextBusinessHours.getHours();
        const minutes = nextBusinessHours.getMinutes().toString().padStart(2, '0');
        const timeStr = `klo ${hours}.${minutes}`;
    
        // Tänään
        if (
            nextBusinessHours.getDate() === now.getDate() &&
            nextBusinessHours.getMonth() === now.getMonth() &&
            nextBusinessHours.getFullYear() === now.getFullYear()
        ) {
            return `tänään ${timeStr}`;
        }
    
        // Huomenna
        const tomorrow = new Date(now);
        tomorrow.setDate(now.getDate() + 1);
        if (
            nextBusinessHours.getDate() === tomorrow.getDate() &&
            nextBusinessHours.getMonth() === tomorrow.getMonth() &&
            nextBusinessHours.getFullYear() === tomorrow.getFullYear()
        ) {
            return `huomenna ${timeStr}`;
        }
    
        // Muulloin
        return `${dayName} ${timeStr}`;
    };
    

    // Handle leave message form submission
    const handleLeaveMessage = async () => {
        if (!leaveMessageText.trim()) return;

        setIsLoading(true);

        try {
            // Prepare message data
            const messageData = {
                widget_id: widgetData?.bot_id,
                session_id: sessionIdRef.current,
                message: leaveMessageText + "\n" + leaveMessageName + "\n" + leaveMessageEmail,
                name: leaveMessageName,
                email: leaveMessageEmail,
                timestamp: new Date().toISOString(),
                from_role: 'user',
                outside_hours: true
            };

            // Send to server
            await fetch(`${getApiUrl()}/widget/send-custom-message`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(messageData),
            });

            // Add message to local state
            setMessages(prev => [...prev, {
                type: 'user',
                content: leaveMessageText,
                timestamp: new Date()
            }]);

            // Clear form and show confirmation
            setLeaveMessageText('');
            setLeaveMessageSubmitted(true);

            // Add system message to show confirmation
            setTimeout(() => {
                setMessages(prev => [...prev, {
                    type: 'bot',
                    content: "Kiitos viestistäsi! Palaamme asiaan mahdollisimman pian.",
                    timestamp: new Date()
                }]);
            }, 500);

        } catch (error) {
            console.error('Error sending message:', error);
            setMessages(prev => [...prev, {
                type: 'bot',
                content: "Valitettavasti viestin lähetys epäonnistui. Yritäthän uudelleen myöhemmin.",
                timestamp: new Date()
            }]);
        } finally {
            setIsLoading(false);
        }
    };

    const newSessionId = () => {
        const new_session_id = Math.random().toString(36).substring(2, 12);
        localStorage.setItem('session_id', new_session_id);
        return new_session_id;
    }

    useEffect(() => {
        let sessionId = localStorage.getItem('session_id');
        if (!sessionId) {
            sessionId = newSessionId();
        }
        sessionIdRef.current = sessionId;

        // 1) Sign in anonymously so we have an auth.uid
        signInAnonymously(auth).catch((error) => {
            console.error("Anon auth failed:", error);
        });

        // 2) Once authenticated, start real-time subscription
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // If your Security Rule is auth.uid === session_id, you must
                // update the user's UID to match session_id. But by default,
                // anonymous auth will generate a random UID.
                // 
                // For this simple example, you can't directly "set" auth.uid 
                // to your existing session_id in front-end code. You'd need 
                // a custom auth token from your server if you want them to match.
                // 
                // Alternatively, keep your rules simpler:
                // ".read": "auth != null" 
                // and only read the single path. 
                // Or do a server-based approach for read as well.

                startRealtimeListener();
            }
        });

        return () => unsubscribe && unsubscribe();
    }, [widgetData]);


    // Load business hours configuration from Firebase
    useEffect(() => {
        const loadBusinessHours = async () => {
            if (!widgetData?.bot_id) return;

            try {
                const configRef = ref(db, `config/${widgetData.bot_id}/businessHours`);
                const snapshot = await get(configRef);

                if (snapshot.exists()) {
                    const config = snapshot.val();
                    setBusinessHoursConfig(config);
                    // Check if current time is within business hours
                    const within = isWithinBusinessHours(config);
                    setIsWithinHours(within);

                    console.log(within, config)
                } else {
                    // Default to always within hours if no config
                    setIsWithinHours(true);
                }
            } catch (error) {
                console.error('Error loading business hours:', error);
                // Default to within hours on error
                setIsWithinHours(true);
            }
        };

        loadBusinessHours();

        // Set up interval to check business hours every minute
        const interval = setInterval(() => {
            if (businessHoursConfig) {
                const within = isWithinBusinessHours(businessHoursConfig);
                setIsWithinHours(within);
            }
        }, 60000); // Check every minute

        return () => clearInterval(interval);
    }, [widgetData?.bot_id]);

    const startRealtimeListener = () => {
        if (!widgetData?.bot_id || !sessionIdRef.current) {
            console.log("Missing required data:", { widgetId: widgetData?.bot_id, sessionId: sessionIdRef.current });
            return;
        }

        const widgetId = widgetData.bot_id;
        const sessionId = sessionIdRef.current;
        const chatPath = `messages/${widgetId}/${sessionId}`;

        console.log("Starting Firebase listener at path:", chatPath);

        const chatRef = ref(db, chatPath);

        onValue(chatRef, (snapshot) => {
            if (!snapshot.exists()) {
                console.log("Firebase: No messages exist");
                return;
            }


            setMessages(prevMessages => {
                const messagesData = snapshot.val();
                console.log(messagesData)
                const processedMessages = Object.entries(messagesData)?.filter(
                    ([key, msgData]) => msgData?.message?.length > 0
                ).map(([key, msgData]) => ({
                    id: key,
                    content: msgData.message,
                    timestamp: parseTimestamp(msgData.timestamp),
                    type: mapFromRole(msgData.from)
                })).sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

                // Create a Set of existing message IDs for efficient lookup
                const existingIds = new Set(prevMessages.map(msg => msg.id));

                // Filter out messages that already exist
                const newMessages = processedMessages.filter(msg => !existingIds.has(msg.id));

                return [...prevMessages, ...newMessages];
            });
        }, (error) => {
            console.error("Firebase listener error:", error);
        });
    };
    // Utility to map from "from" field to a local "type"
    const mapFromRole = (fromVal) => {
        if (fromVal === 'user') return 'user';
        if (fromVal === 'admin') return 'bot'; // or 'admin'
        if (fromVal === 'system') return 'system';
        return 'bot';
    };

    const parseTimestamp = (ts) => {
        console.log(ts)
        if (!ts) return new Date();
        if (typeof ts === 'string' && ts.includes("klo")) {
            const [datePart, timePart] = ts.split(" klo ");
            const [day, month, year] = datePart.split(".");
            const [hours, minutes] = timePart.split(":");
            return new Date(year, month - 1, day, hours, minutes);
        }
        // If numeric, convert
        if (typeof ts === 'number') return new Date(ts);
        // If string, try parse
        try {
            return new Date(ts);
        } catch {
            return new Date();
        }
    };


    // Show/hide bottom sheet when a form is present.
    useEffect(() => {
        if (currentForm) {
            setShowSheet(true);
        } else {
            setShowSheet(false);
        }
    }, [currentForm]);

    const handleCloseSheet = () => {
        // If form is "required" and you truly don't want them to close,
        // simply do nothing here. Otherwise:
        setShowSheet(false);
        setCurrentForm(null);
    };

    const onSendCustomMessage = async (message) => {
        if (!isWithinHours && businessHoursConfig?.enabled) {
            // Add message to local state
            setMessages(prev => [...prev, {
                type: 'user',
                content: message,
                timestamp: new Date()
            }]);

            // Add system message about business hours
            setTimeout(() => {
                setMessages(prev => [...prev, {
                    type: 'bot',
                    content: businessHoursConfig.outOfHoursMessage || "Asiakaspalvelumme on tällä hetkellä suljettu. Jätäthän viestin – palaamme asiaan heti, kun mahdollista.",
                    timestamp: new Date()
                }]);
            }, 500);

            return;
        }

        // Then do the backend POST
        let payload = {
            widget_id: widgetData?.bot_id,
            message: message,
            session_id: sessionIdRef.current,
            from_role: 'user',
        };
        if (aiChatOpen) {
            try {
                const ai_payload = {
                    ...payload,
                    system_prompt: currentAction?.ai_chat_prompt ?? "Olet avulias avustaja.",
                    conversation: [
                        ...messages.map(msg => ({
                            role: msg.type === 'user' ? 'user' : 'assistant',
                            content: msg.content,
                        })),
                        {
                            role: 'user',
                            content: message,
                        }
                    ],
                };

                const response = await fetch(`${getApiUrl()}/widget/send-ai-chat`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(ai_payload),
                });
                const result = await response.json();
                // Huomaa: Vastaus tallennetaan Firebaseen backendissä, jolloin reaaliaikainen listener näyttää sen.
            } catch (error) {
                console.error("Virhe ai_chat-toiminnossa:", error);
            }
            return;
        }

        try {
            await fetch(`${getApiUrl()}/widget/send-custom-message`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });
        } catch (err) {
            console.error("Failed to send message:", err);
        }
    };

    // Set button height on current options change.
    useEffect(() => {
        if (buttonContainerRef.current) {
            setButtonHeight(buttonContainerRef.current.offsetHeight);
        } else {
            setButtonHeight(0);
        }
        return () => {
            setButtonHeight(0);
        };
    }, [currentOptions]);

    // Helper to create trackable timeouts.
    const createTimeout = (callback, delay) => {
        const id = setTimeout(callback, delay);
        timeoutIds.current.push(id);
        return id;
    };

    // Clear all timeouts.
    const clearAllTimeouts = () => {
        timeoutIds.current.forEach(id => clearTimeout(id));
        timeoutIds.current = [];
    };

    // Calculate delay based on message length.
    const calculateDelay = (message) => {
        const baseDelay = 200;
        const delayPerChar = 20;
        const maxDelay = 1000;
        return Math.min(baseDelay + message.length * delayPerChar, maxDelay);
    };

    // Track chat loaded event when ChatSimple mounts.
    useEffect(() => {
        analytics.trackEvent('chat_loaded', { stepId: currentStepId });
        console.log(openingMessage, "openingMessage")
    }, []);

    // Initialize chat when innerWindowOpen is true.
    useEffect(() => {
        const initializeChat = async () => {
            clearAllTimeouts();
            setMessages([]);
            setCurrentOptions(null);
            setCurrentForm(null);
            setLeaveMessageSubmitted(false);

            let parsedFlow = {};
            if (widgetData?.predefined_ai_chat_json?.length > 0) {
                try {
                    parsedFlow = JSON.parse(widgetData.predefined_ai_chat_json);
                    setChatFlow(parsedFlow);
                } catch (error) {
                    console.error('Failed to parse chat flow:', error);
                    return;
                }
            }

            // Wait a tick to ensure chatFlow is set.
            await new Promise(resolve => setTimeout(resolve, 1));

            // Immediately track a chat start event.
            analytics.trackEvent('chat_start', { initialStepId: openingMessage?.topicId ?? "start", url: window.location.href });

            // if live chat selected, then check if it's available
            const initialStepId2 = openingMessage?.topicId ?? "start";
            const flow = parsedFlow[initialStepId2];
            setLiveChatOpen(flow?.action?.type === 'live_chat');
            // Check business hours before proceeding
            if (!isWithinHours && businessHoursConfig?.enabled && flow?.action?.type === 'live_chat') {
                // Outside business hours - show message
                setMessages([{
                    type: 'bot',
                    content: businessHoursConfig.outOfHoursMessage || "Asiakaspalvelumme on tällä hetkellä suljettu. Jätäthän viestin – palaamme asiaan heti, kun mahdollista.",
                    timestamp: new Date()
                }]);

                // Get next business hours
                const nextHours = getNextBusinessHours(businessHoursConfig);
                if (nextHours) {
                    const formattedNext = formatNextBusinessHours(nextHours);
                    setMessages(prev => [...prev, {
                        type: 'bot',
                        content: `Palaamme ${formattedNext}.`,
                        timestamp: new Date()
                    }]);
                }

                return;
            }

            // Set the opening message if provided.
            if (openingMessage?.short_topic_button_title) {
                setMessages([{
                    type: 'user',
                    content: openingMessage.short_topic_button_title,
                    timestamp: new Date()
                }]);
            }

            // Proceed to first step.
            const initialStepId = openingMessage?.topicId ?? "start";
            if (parsedFlow[initialStepId]) {
                newSessionId();
                await proceedToNextStep(initialStepId, parsedFlow);
            }
        };

        if (innerWindowOpen) {
            initializeChat();
        }
    }, [openingMessage, widgetData, chatKey, innerWindowOpen, isWithinHours, businessHoursConfig]);

    const onCloseBottomSheet = () => {
        setShowSheet(false);
        setCurrentForm(null);
        setMessages(prev => [...prev, {
            type: 'bot',
            content: "Miten haluat edetä?",
            timestamp: new Date()
        }]);
        const options = widgetData?.INITIAL_CHAT_OPENING_QUESTIONS.map(q => ({
            text: q?.short_topic_button_title,
            nextStep: q?.topicId,
        }));
        setCurrentOptions(options);
        analytics.trackEvent('sheet_closed', {});
    };

    const proceedToNextStep = async (stepId, parsedFlow) => {
        return new Promise(async (resolve) => {
            setCurrentForm(null);
            setCurrentOptions(null);

            if (!stepId) {
                console.warn('No stepId provided to proceedToNextStep');
                resolve();
                return;
            }

            const nextStep = parsedFlow[stepId];
            if (!nextStep) {
                console.warn(`Step ${stepId} not found in chatFlow`);
                resolve();
                return;
            }

            // Simulate typing indicator by sending bot messages sequentially.
            const sendMessage = async (content, type, timestamp) => {
                setIsLoading(true);
                const delay = calculateDelay(content);
                await new Promise(resolve => createTimeout(resolve, delay));
                setIsLoading(false);
                setMessages(prev => [...prev, { type, content, timestamp }]);
                await new Promise(resolve => createTimeout(resolve, 100));
                setIsLoading(true);
            };

            const sendMessagesSequentially = async (contentArray, type, timestamp) => {
                for (const content of contentArray) {
                    await sendMessage(content, type, timestamp);
                }
            };

            const timestamp = new Date();
            if (Array.isArray(nextStep.content)) {
                await sendMessagesSequentially(nextStep.content, nextStep.type, timestamp);
            } else {
                await sendMessage(nextStep.content, nextStep.type, timestamp);
            }
            setIsLoading(true);
            await new Promise(resolve => createTimeout(resolve, 400));
            setIsLoading(false);
            setCurrentStepId(stepId);

            // After sending bot messages, track a 'bot_message_sent' event.
            analytics.trackEvent('bot_message_sent', { stepId, content: nextStep.content });

            setIsExitingButtons(false);
            setLiveChatOpen(false);
            setAiChatOpen(false);
            if (nextStep.action) {
                setCurrentAction(nextStep.action);
                if (nextStep.action.type === 'form') {
                    await new Promise(resolve => createTimeout(resolve, 600));
                    setCurrentForm(nextStep.action);
                    setCurrentOptions(null);
                    // Do not resolve; the form submission will continue the flow.
                    return;
                } else if (nextStep.action.type === 'buttons') {
                    setCurrentOptions(nextStep.action.options);
                    setCurrentForm(null);
                } else if (nextStep.action.type === 'link') {
                    window.open(nextStep.action.url, '_blank');
                    await proceedToNextStep(nextStep.action.nextStep, chatFlow);
                    resolve();
                } else if (nextStep.action.type === 'live_chat') {
                    setCurrentForm(null);
                    setCurrentOptions(null);
                    setLiveChatOpen(true);
                } else if (nextStep.action.type === 'ai_chat') {
                    // Uusi "ai_chat" -toiminto: kutsutaan backendiä, joka käyttää OpenAI:a
                    setLiveChatOpen(true);
                    setAiChatOpen(true);
                    setCurrentForm(null);
                    setCurrentOptions(null);
                }
            } else {
                setCurrentForm(null);
                setCurrentOptions(null);
                if (nextStep.nextStep) {
                    await proceedToNextStep(nextStep.nextStep, chatFlow);
                    resolve();
                } else {
                    resolve();
                }
            }
        });
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            const scrollContainer = messagesEndRef.current.parentElement;
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }
    };

    const submitForm = async (formData) => {
        const payload = {
            widget_id: widgetData?.bot_id,
            notification_email: widgetData?.notification_emails,
            sender_info: {
                url: window.location.href,
                userAgent: navigator.userAgent,
            },
            ...formData,
        };

        const response = await fetch(getApiUrl() + '/widget/send-email', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        });
        if (!response.ok) {
            console.error('Failed to submit form:', response.statusText);
        }
    };

    useEffect(() => {
        const scrollAfterAdjustment = setTimeout(() => {
            scrollToBottom();
        }, 100);
        return () => clearTimeout(scrollAfterAdjustment);
    }, [messages, currentForm, currentOptions, buttonHeight]);

    const handleFormSubmit = async (formData) => {
        setMessages(prev => [
            ...prev,
            { type: 'user', content: '-', timestamp: new Date() }
        ]);

        // Track that user submitted form info.
        analytics.trackEvent('form_submitted', { stepId: currentStepId, formData });
        analytics.trackFormSubmit(formData, currentStepId);

        await new Promise(resolve => createTimeout(resolve, 200));

        setAllFormFields(prevFields => {
            const updatedFields = { ...prevFields, [currentStepId]: formData };
            if (currentForm?.does_submit) {
                submitForm({ form_data: updatedFields });
                return null;
            }
            return updatedFields;
        });

        // Check if current step is part of a multiselect flow
        const isMultiSelectFollowUp = Object.entries(chatFlow).some(([_, step]) => {
            const multiSelectFields = step.action?.fields?.filter(f => f.type === 'multiselect') || [];
            return multiSelectFields.some(field =>
                field.options.some(opt => opt.nextStep === currentStepId)
            );
        });

        if (isMultiSelectFollowUp) {
            // Find the original multiselect step and its selections
            let originalStep, originalSelections;
            for (const [stepId, step] of Object.entries(chatFlow)) {
                const multiSelectFields = step.action?.fields?.filter(f => f.type === 'multiselect') || [];
                if (multiSelectFields.some(field =>
                    field.options.some(opt => opt.nextStep === currentStepId)
                )) {
                    originalStep = step;
                    originalSelections = allFormFields[stepId];
                    break;
                }
            }

            if (originalSelections) {
                // Find the next unprocessed option
                const multiSelectField = originalStep.action.fields.find(f => f.type === 'multiselect');
                const selectedOptions = originalSelections[multiSelectField.name] || [];

                const currentOptionIndex = selectedOptions.findIndex(opt =>
                    opt.nextStep === currentStepId
                );

                if (currentOptionIndex >= 0 && currentOptionIndex < selectedOptions.length - 1) {
                    // Process next option
                    const nextOption = selectedOptions[currentOptionIndex + 1];
                    if (nextOption.nextStep) {
                        await proceedToNextStep(nextOption.nextStep, chatFlow);
                        return;
                    }
                }

                // If we've processed all options, proceed to the original form's nextStep
                if (originalStep.action.nextStep) {
                    await proceedToNextStep(originalStep.action.nextStep, chatFlow);
                    return;
                }
            }
        }

        // Handle initial multiselect selection
        const multiSelectFields = currentForm?.fields?.filter(field => field.type === 'multiselect') || [];
        if (multiSelectFields.length > 0) {
            for (const field of multiSelectFields) {
                const selectedValues = formData[field.name] || [];

                // Find first option with nextStep
                const firstOption = selectedValues.find(value => value.nextStep);
                if (firstOption?.nextStep) {
                    await proceedToNextStep(firstOption.nextStep, chatFlow);
                    return;
                }
            }
        }

        const formNextStep = currentForm?.nextStep;
        if (formNextStep) {
            await proceedToNextStep(formNextStep, chatFlow);
        }
    };

    const handleOptionClick = async (option) => {
        setIsExitingButtons(true);
        setMessages(prev => [
            ...prev,
            { type: 'user', content: option.text, timestamp: new Date() }
        ]);

        // Instead of trackMessage, we track a dedicated 'option_clicked' event.
        analytics.trackEvent('option_clicked', {
            optionText: option.text,
            stepId: currentStepId,
            nextStep: option.nextStep
        });

        await proceedToNextStep(option.nextStep, chatFlow);
        await new Promise(resolve => setTimeout(resolve, 200));
        setIsExitingButtons(false);
    };

    useEffect(() => {
        return () => {
            analytics.endChat();
        };
    }, []);

    return (
        <Panel>
            <ChatContainer backgroundColor={widgetData?.chatButtonBackgroundColor}>
                {businessHoursConfig?.enabled && !isWithinHours && liveChatOpen && (
                    <BusinessHoursContainer>
                    <strong>Chat on tällä hetkellä suljettu</strong>
                    {getNextBusinessHours(businessHoursConfig) && (
                        <p style={{ margin: '5px 0 0 0', fontSize: '0.9em' }}>
                            Chat avautuu {formatNextBusinessHours(getNextBusinessHours(businessHoursConfig))}
                        </p>
                    )}
                    </BusinessHoursContainer>
                )}
                
                <ScrollContainer 
                    buttonheight={showSheet ? 0 : buttonHeight}
                    style={{ 
                        paddingTop: businessHoursConfig?.enabled && !isWithinHours && liveChatOpen ? '80px' : '10px'
                    }}
                >
                    {messages.map((message, index) => {
                        const prevMessage = messages[index - 1];
                        const isVisible = !prevMessage || prevMessage.type !== message.type;
                        return (
                            <ChatMessage key={index} delay={`${0.1}s`} type={message.type}>
                                {message.type !== 'user' &&
                                    <ChatAvatar
                                        isVisible={isVisible}
                                        hideAvatar={widgetData?.doNotDisplayLogoChat}
                                        logoUrl={message.type === 'user' ? null : (widgetData?.chatAvatarImage ?? widgetData?.logo)}
                                        defaultText={message.type === 'user' ? 'U' : 'B'}
                                    />
                                }
                                <div
                                    style={{
                                        maxWidth: '70%',
                                        padding: '10px',
                                        position: 'relative',
                                        backgroundColor: message.type === 'user' ? widgetData?.secondary_theme_color : '#fff',
                                        borderRadius: '12px',
                                        borderTopRightRadius: message.type === 'user' ? '2px' : '12px',
                                        borderTopLeftRadius: message.type === 'user' ? '12px' : '2px',
                                        marginLeft: message.type === 'user' ? 'auto' : '0',
                                        border: '1px solid #d7d7d94d',
                                        fontWeight: message.type === 'user' ? 600 : '400',
                                        color: message.type === 'user'
                                            ? isColorDark(widgetData?.secondary_theme_color) ? '#f1f1f1' : '#333333'
                                            : '#333333'
                                    }}
                                >
                                    <p style={{ margin: 0, overFlow: "hidden", whiteSpace: "pre-wrap" }}>{message.content}</p>
                                    <small style={{
                                        fontSize: '0.7em',
                                        color: message.type === 'user'
                                            ? isColorDark(widgetData?.secondary_theme_color) ? '#e6e6e6' : '#333333'
                                            : '#333333'
                                    }}>
                                        {message?.timestamp?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                    </small>
                                </div>
                            </ChatMessage>
                        );
                    })}
                    
                    {/* Leave a message form when outside business hours */}
                    {businessHoursConfig?.enabled && !isWithinHours && messages.length > 0 && !leaveMessageSubmitted && liveChatOpen && (
                        <LeaveMessageForm>
                            <h4 style={{ marginTop: 0 }}>Jätä viesti</h4>
                            <Input
                                placeholder="Nimesi"
                                value={leaveMessageName}
                                onChange={setLeaveMessageName}
                                style={{ marginBottom: '10px', width: '100%' }}
                            />
                            <Input
                                placeholder="Puhelinnumero"
                                type="email"
                                value={leaveMessageEmail}
                                onChange={setLeaveMessageEmail}
                                style={{ marginBottom: '10px', width: '100%' }}
                            />
                            <Input
                                as="textarea"
                                rows={3}
                                placeholder="Kirjoita viestisi"
                                value={leaveMessageText}
                                onChange={setLeaveMessageText}
                                style={{ marginBottom: '10px', width: '100%' }}
                            />

                            <Button
                                appearance="primary"
                                color="blue"
                                onClick={handleLeaveMessage}
                                disabled={!leaveMessageText.trim()}
                                loading={isLoading}
                                style={{ 
                                    backgroundColor: widgetData?.theme_color,
                                    color: widgetData?.chatSelectionButtonTextColor || 'white'
                                }}
                            >
                                Lähetä viesti
                            </Button>
                        </LeaveMessageForm>
                    )}
                    
                    {isLoading && (
                        <div style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'flex-start',
                            background: 'transparent'
                        }}>
                            <ChatAvatar hideAvatar={widgetData?.doNotDisplayLogoChat} logoUrl={(widgetData?.chatAvatarImage ?? widgetData?.logo)} />
                            <TypingIndicator />
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </ScrollContainer>
    
                {currentOptions && (
                    <ButtonContainer backgroundColor={widgetData?.chatButtonBackgroundColor} ref={buttonContainerRef} isExiting={isExitingButtons} className="buttonContainer">
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            gap: '10px',
                            width: '100%',
                            justifyContent: 'right'
                        }}>
                            {currentOptions.map((option, idx) => {
                                const nextStep = chatFlow[option?.nextStep];
                                const isExternalLink = nextStep?.action?.type === "link";
                                return (
                                    <AnimatedButton
                                        key={idx}
                                        className="selectionButton"
                                        onClick={() => handleOptionClick(option)}
                                        themeColor={widgetData?.theme_color}
                                        delay={idx * 100}
                                        style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                                        aria-label={isExternalLink ? `${option.text} (opens in new tab)` : option.text}
                                    >
                                        <span style={{ color: widgetData?.chatSelectionButtonTextColor || 'inherit' }}>{option.text}</span>
                                        {isExternalLink && (
                                            <span aria-hidden="true" style={{ fontSize: '0.85em', marginLeft: '2px', display: 'inline-flex' }}>
                                                <svg width="14" height="14" viewBox="0 0 24 24" fill="currentColor">
                                                    <path d="M5 3c-1.093 0-2 .907-2 2v14c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2v-7h-2v7H5V5h7V3H5zm9 0v2h3.586l-9.293 9.293 1.414 1.414L19 6.414V10h2V3h-7z" />
                                                </svg>
                                            </span>
                                        )}
                                    </AnimatedButton>
                                );
                            })}
                        </div>
                    </ButtonContainer>
                )}
    
                {/* Only show ChatSend if within business hours or business hours are not enabled */}
                {(!businessHoursConfig?.enabled || isWithinHours || leaveMessageSubmitted) && (
                    <ChatSend 
                        onSend={onSendCustomMessage} 
                        liveChatOpen={liveChatOpen} 
                        widgetData={widgetData} 
                        buttonContainerRef={buttonContainerRef} 
                        isExitingButtons={isExitingButtons} 
                    />
                )}
    
                <BottomSheet isVisible={showSheet} onClose={onCloseBottomSheet} bgColor={widgetData?.chatButtonBackgroundColor}>
                    {currentForm && (
                        <MultiStepForm
                            formConfig={currentForm}
                            onSubmit={handleFormSubmit}
                            widgetData={widgetData}
                        />
                    )}
                </BottomSheet>
            </ChatContainer>
        </Panel>
    );
};

export default ChatSimple;
