import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import { Blocks } from 'react-loader-spinner'
import { VirtuaaliApuriTest } from './pages/VirtuaaliApuriTest';
import { VirtuaaliApuriWidget } from "./pages/VirtuaaliApuriWidget";


const AppSurveyMainLazy = React.lazy(() => {
  if (window.location.pathname !== '/landingPage2') {
    return import('./AppSurveyMain');
  }
  return () => null;
});

const VirtuaaliApuriTemplate = React.lazy(() => {
  return import('./openReactTemplate/VirtuaaliApuriTemplate');
});

export default function SurveyJSReactApplication() {
  useEffect(() => {
    // Only add script if we're on the root path
    if (window.location.pathname === '/') {
      const script = document.createElement('script');
      script.src = "http://localhost:5000/widget/1lm0p2/script.js";
      script.async = true;
      script.defer = true;
      script.crossOrigin = "anonymous";
      document.body.appendChild(script);

      return () => {
        // Remove script when component unmounts
        if (script && document.body.contains(script)) {
          document.body.removeChild(script);
        }
      };
    }
  }, []);
  
  React.useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: "G-NP0TGVZXTX"
      },
      {
        trackingId: "AW-10843945043"
      }
    ]);

    
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });


    const callback = function () {
      if (typeof (url) !== 'undefined') {
        window.location = url;
      }
    };


    ReactGA.event({
      action: 'conversion',
      category: 'AW-10843945043/1OhpCPrN25EYENPw5bIo',
      label: 'AW-10843945043/1OhpCPrN25EYENPw5bIo',
      event_callback: callback
    });

    ReactGA.event({
      action: 'conversion',
      category: 'AW-10843945043/Qnm4CP3N25EYENPw5bIo',
      label: 'AW-10843945043/Qnm4CP3N25EYENPw5bIo',
      event_callback: callback
    });
  }, []);

  function throttle(func, limit) {
    let inThrottle;
    return function (...args) {
      if (!inThrottle) {
        func.apply(this, args);
        inThrottle = true;
        setTimeout(() => inThrottle = false, limit);
      }
    };
  }

  useEffect(() => {
    // Helper function to dispatch events
    const sendMessage = (data) => {
      window.postMessage(data, '*');
    };

    // Throttled scroll tracking (200ms)
    const throttledScroll = throttle(() => {
      sendMessage({
        type: 'SCROLL_POSITION',
        scrollY: window.scrollY
      });
    }, 200);

    window.addEventListener('scroll', throttledScroll);

    // Exit intent detection
    document.addEventListener('mouseleave', (e) => {
      if (e.clientY <= 0) {
        sendMessage({ type: 'EXIT_INTENT' });
      }
    });

    // Viewport dimensions
    const sendViewportDimensions = () => {
      sendMessage({
        type: 'VIEWPORT_DIMENSIONS',
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    sendViewportDimensions();

    // URL tracking
    const sendCurrentURL = () => {
      sendMessage({
        type: 'CURRENT_URL',
        href: window.location.href,
        pathname: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash
      });
    };

    sendCurrentURL();

    // URL change detection for SPAs
    const observeUrlChanges = () => {
      let lastUrl = location.href;
      new MutationObserver(() => {
        const url = location.href;
        if (url !== lastUrl) {
          lastUrl = url;
          sendCurrentURL();
        }
      }).observe(document, { subtree: true, childList: true });
    };
    observeUrlChanges();

    // Inactivity tracking
    let inactivityTimer;
    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        sendMessage({ type: 'INACTIVITY' });
      }, 30000);
    };

    const userActivityEvents = ['mousemove', 'mousedown', 'keypress', 'touchstart', 'scroll'];
    userActivityEvents.forEach(event => {
      document.addEventListener(event, resetInactivityTimer);
    });
    resetInactivityTimer();

    // Click tracking
    document.addEventListener('click', (e) => {
      sendMessage({
        type: 'CLICK_EVENT',
        target: e.target.tagName,
        id: e.target.id || null,
        classList: Array.from(e.target.classList)
      });
    });

    // Focus tracking
    window.addEventListener('focus', () => {
      sendMessage({ type: 'FOCUS_CHANGE', focus: true });
    });

    window.addEventListener('blur', () => {
      sendMessage({ type: 'FOCUS_CHANGE', focus: false });
    });

    // Visibility tracking
    document.addEventListener('visibilitychange', () => {
      sendMessage({
        type: 'VISIBILITY_CHANGE',
        visible: document.visibilityState === 'visible'
      });
    });

    // Cleanup
    return () => {
      window.removeEventListener('scroll', () => { });
      document.removeEventListener('mouseleave', () => { });
      window.removeEventListener('resize', sendViewportDimensions);
      document.removeEventListener('click', () => { });
      window.removeEventListener('focus', () => { });
      window.removeEventListener('blur', () => { });
      document.removeEventListener('visibilitychange', () => { });
    };
  }, []);

  const url = new URL(window.location.href);
  const pages = ['myynti', 'soita', 'demo', 'tarjous', '', 'asennus', 'widgetEditor', 'hallintapaneeli', 'hello'];
  const currentPath = url.pathname.replace('/', ''); // Remove leading slash

  let virtuaaliApuri = false;
  if (url.hostname === 'localhost' || url.hostname === '127.0.0.1') {
    // Allow any page
    virtuaaliApuri = true;
  } else if (url.hostname === 'virtuaaliapuri.com') {
    // Allow only specific pages
    virtuaaliApuri = true;
    document.title = 'VirtuaaliApuri | Tehosta Myyntiä ja Asiakaspalvelua';
    if (!pages.includes(currentPath)) {
      // Redirect to Google if page is not in 'pages'
      window.location.href = 'https://www.google.fi';
      return;
    }
  }

  return (
    <Router>
      <Switch>
        <Route path="/virtuaaliApuri/:id">
          <VirtuaaliApuriTest />
        </Route>
        <Route exact path="/">
          <React.Suspense fallback={<Blocks
            visible={true}
            height="160"
            width="160"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          />}>
            {
              virtuaaliApuri ? (<>
                <VirtuaaliApuriTemplate />
              </>
              ) :
                <p>Survey not found. Please check survey URL.</p>
            }
          </React.Suspense>
        </Route>
        <Route path="*">
          <React.Suspense fallback={<Blocks
            visible={true}
            height="160"
            width="160"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          />}>
            <AppSurveyMainLazy />
          </React.Suspense>
        </Route>
      </Switch>
    </Router>
  )
}


