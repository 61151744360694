// firebaseClient.js
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';

export const firebaseConfigVirtuaaliApuri = {
    apiKey: "AIzaSyBd9xVb_zy0_cuAQadiANJ9GZb7ZzKMrlU",
    authDomain: "surveytool-c7892.firebaseapp.com",
    projectId: "surveytool-c7892",
    storageBucket: "surveytool-c7892.appspot.com",
    messagingSenderId: "116171802798",
    appId: "1:116171802798:web:4625b05b34e9a34210c53f",
    measurementId: "G-NP0TGVZXTX",
    databaseURL: 'https://virtuaaliapuri-com-3c351.europe-west1.firebasedatabase.app/'
};

let firebaseApp;
if (!getApps().length) {
  // If no apps initialized, initialize the default one
  firebaseApp = initializeApp(firebaseConfigVirtuaaliApuri, "clientVA");
} else {
  // If already initialized, use that one
  firebaseApp = getApp(); 
}

export const db = getDatabase(firebaseApp);
export const auth = getAuth(firebaseApp);
