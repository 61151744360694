import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { IconButton } from 'rsuite';
import SendIcon from '@rsuite/icons/Send';
import AttachmentIcon from '@rsuite/icons/Attachment';

const ChatInputWrapper = styled.div`
  border: 1px solid #E2E8F0;
  border-radius: 29px;
  background: white;
  margin: 2px 15px;
  margin-top: 0;
  min-height: 48px;
  height: fit-content;
  display: flex;
  padding: 5px 10px;
  justify-content: space-between;
  transition: var(--transition);
`;

const ChatInputLeft = styled.div`
  flex: 1;
  margin:auto;
`;

const ChatInputRight = styled.div`
  display: flex;
  gap: 8px;
  margin-right: 10px;
  align-items: center;
`;

const ChatTextArea = styled.textarea`
  border: 0;
  margin: 0;
  width: 100%;
  padding: 4px 10px !important;
  min-height: 28px;
  height: 28px; /* initial height */
  color: #000;
  background: white;
  border-radius: 15px;
  box-shadow: none !important;
  font-size: 14px !important;
  outline: none;
  resize: none !important;
  font-family: inherit;
  max-height: 130px;
  overflow: hidden;
  overflow-wrap: break-word;
`;

const ChatInput = ({ onSend, onFileAttach, liveChatOpen }) => {
    const [message, setMessage] = useState('');
    const textareaRef = useRef(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        setMessage('');
    }, [liveChatOpen]);

    // Adjust the textarea height based on its scrollHeight
    const autoResize = (e) => {
        const textarea = e.target;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };


    const handleInputChange = (e) => {
        setMessage(e.target.value);
        autoResize(e);
    };

    const handleIconClick = () => {
        if (message.trim() === '') {
            // If there's no text, trigger the file attachment input
            if (fileInputRef.current) {
                fileInputRef.current.click();
            }
        } else {
            // Otherwise, send the message
            if (onSend) {
                onSend(message);
            }
            setMessage('');
            // Reset textarea height
            if (textareaRef.current) {
                textareaRef.current.style.height = '20px';
            }
        }
    };

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            if (onFileAttach) {
                onFileAttach(file);
            }
        }
    };

    // Choose icon based on whether the user has entered any text
    const IconToShow = message.trim() === '' ? AttachmentIcon : SendIcon;

    if (!liveChatOpen) return null;

    return (
        <ChatInputWrapper >
            <ChatInputLeft>
                <ChatTextArea
                    ref={textareaRef}
                    placeholder="Kirjoita tähän…"
                    value={message}
                    onChange={handleInputChange}
                    rows={1}
                />
            </ChatInputLeft>
            <ChatInputRight>
            <IconButton
          appearance="subtle"
          size="lg"
          icon={<IconToShow/>}
          onClick={handleIconClick}
        />
            </ChatInputRight>
            {/* Hidden file input for attachments */}
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </ChatInputWrapper>
    );
};

export default ChatInput;
