import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 999;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  background: ${({ isMinimized }) =>
    isMinimized ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.4)'};
  backdrop-filter: ${({ isMinimized }) => (isMinimized ? 'none' : 'blur(4px)')};
  transition: background 0.3s ease, backdrop-filter 0.3s ease;
  pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
`;

const BottomSheetWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: ${props => props.backgroundColor ?? '#042b60'};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: ${props => (props.isVisible ? 'translateY(0)' : 'translateY(20px)')};
  transition: opacity 0.6s ease, transform 0.6s ease;

  pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')}; /* Prevent blocking clicks */
  height: ${({ isMinimized }) => (isMinimized ? '100px' : '60vh')};
  max-height: 80%;
  max-width: 600px;
  margin:auto;
  @media (max-width: 768px) {
    height: ${({ isMinimized }) => (isMinimized ? '100px' : '90vh')};
  }
`;

const Header = styled.div`
  position: relative;
  height: 40px;
  background: #f0f0f0;
  border-bottom: ${({ isMinimized }) => (isMinimized ? 'none' : '1px solid #ccc')};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GrabHandle = styled.div`
  width: 40px;
  height: 4px;
  background: #999;
  border-radius: 2px;
  margin-bottom: ${({ isMinimized }) => (isMinimized ? '0' : '8px')};
`;

const Content = styled.div`
  flex: 1;
  overflow-y: ${({ isMinimized }) => (isMinimized ? 'hidden' : 'auto')};
  padding: ${({ isMinimized }) => (isMinimized ? '0' : '16px')};
  display: ${({ isMinimized }) => (isMinimized ? 'none' : 'block')};
`;

const CloseButton = styled.button`
  position: absolute;
  right: 12px;
  top: 8px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
`;

export default function BottomSheet({
  isVisible,
  onClose,
  children,
  bgColor
}) {
  const [isMinimized, setIsMinimized] = useState(false);

  /**
   * Ensure sheet is fully expanded on open.
   */
  useEffect(() => {
    if (isVisible) {
      setIsMinimized(false);
    }
  }, [isVisible]);

  /**
   * Minimize the sheet when the user clicks the header or outside.
   */
  const handleMinimize = () => {
    setIsMinimized(true); // Collapse to 100px
  };

  /**
   * Fully close the sheet when the Close button is clicked.
   */
  const handleCloseClick = (e) => {
    e.stopPropagation(); // Prevent minimize toggle
    onClose && onClose();
  };

  return (
    <>
      <Overlay
        isMinimized={isMinimized}
        isVisible={isVisible} 
        onClick={handleMinimize} />
      <BottomSheetWrapper
        isVisible={isVisible}
        backgroundColor={bgColor}
        isMinimized={isMinimized}
        onClick={(e) => e.stopPropagation()} // Prevent overlay click
      >
        <Header onClick={() => setIsMinimized((prev) => !prev)}>
          <GrabHandle />
          <CloseButton onClick={handleCloseClick}>X</CloseButton>
        </Header>
        <Content>{children}</Content>
      </BottomSheetWrapper>
    </>
  );
}
